<template>
  <div>
    <Html
      :lang="locale"
      dir="ltr"
    >
      <slot />
    </Html>
  </div>
</template>

<script setup lang="ts">
const { locale } = useI18n();
</script>
